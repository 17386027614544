import React, { Component } from "react";
import PropTypes from "prop-types";
import normalizeUrl from "normalize-url";
import {
  getListedTracksForInfoCards,
  getTrackById,
  getAlbumById,
} from "./drawer-items";
import { withRouter } from "react-router-dom";
import InfoCardSlider from "./components/info-card-slider";
import VerticalCenter from "./components/vertical-center";
import FolderTabs from "./components/folder-tabs";
import LyricsModal from "./components/lyrics-modal";
import PostItAudio from "./components/post-it-audio";
import VideosModal from "./components/videos-modal";
import MusicPlayer from "./components/music-player";
import BuyPopup from "./components/buy-popup";
import PlaylistPopup from "./components/playlist-popup";
import DateDisplay from "./components/date-display";
import { LeftButton, RightButton } from "./components/browse-buttons";
import InfoCardAlbums from "./components/info-card-albums";
import SplashScreen from "./splash-screen";
import _ from "lodash";
import cn from "classnames";
import ReactTransitionGroup from "react-addons-transition-group";
import compareReleaseDate from "./util/compare-release-date";
import ScrollBars from "react-scrollbar";
import { monthNumber } from "./util/months";
import { setCurrentTrack, getCurrentDateAsQueryString } from "./currentDate";
import toTimestamp from "./util/to-timestamp";
import { replaceStreamingUrl } from "./util/strings";
import { infoCardIsLockedByID } from "./user";
import PlaylistManager from "./components/new-player/playlist-manager";
import { getThumbnail } from "./services/vimeo";
import { getOrastreamToken, hasSearchedQuery } from "./services/api";
import { updateMetadata } from "./actions/metas";

import original_bs_version from "../images/info-card/stamps/original_bs_version.webp";
import stamp_in_production from "../images/info-card/stamps/stamp_in_production.webp";
import stamp_unreleased_demo from "../images/info-card/stamps/stamp_unreleased_demo.webp";
import stamp_unreleased_instrumental from "../images/info-card/stamps/stamp_unreleased_instrumental.webp";
import stamp_unreleased_live_ver from "../images/info-card/stamps/stamp_unreleased_live_ver.webp";
import stamp_unreleased_livermont from "../images/info-card/stamps/stamp_unreleased_livemont.webp";
import stamp_unreleased_mix from "../images/info-card/stamps/stamp_unreleased_mix.webp";
import stamp_unreleased_orig_vers from "../images/info-card/stamps/stamp_unreleased_orig_vers.webp";
import stamp_unreleased_song from "../images/info-card/stamps/stamp_unreleased_song.webp";
import stamp_unreleased_version from "../images/info-card/stamps/stamp_unreleased_version.webp";
import stamp_unreleased from "../images/info-card/stamps/stamp_unreleased.webp";
import stamp_version1 from "../images/info-card/stamps/stamp_version1.webp";
import stamp_version2 from "../images/info-card/stamps/stamp_version2.webp";
import unfinished_recording from "../images/info-card/stamps/unfinished_recording.webp";
import unreleased_2009_mix from "../images/info-card/stamps/unreleased_2009_mix.webp";
import unreleased_original from "../images/info-card/stamps/unreleased_original.webp";
import { connect } from "react-redux";
import { EmbedModal } from "./embed-modal";
import MenuPopup from "./components/menu-popup";

const stampImages = {
  "original_bs_version.webp": original_bs_version,
  "stamp_in_production.webp": stamp_in_production,
  "stamp_unreleased_demo.webp": stamp_unreleased_demo,
  "stamp_unreleased_instrumental.webp": stamp_unreleased_instrumental,
  "stamp_unreleased_live_ver.webp": stamp_unreleased_live_ver,
  "stamp_unreleased_livermont.webp": stamp_unreleased_livermont,
  "stamp_unreleased_mix.webp": stamp_unreleased_mix,
  "stamp_unreleased_orig_vers.webp": stamp_unreleased_orig_vers,
  "stamp_unreleased_song.webp": stamp_unreleased_song,
  "stamp_unreleased_version.webp": stamp_unreleased_version,
  "stamp_unreleased.webp": stamp_unreleased,
  "stamp_version1.webp": stamp_version1,
  "stamp_version2.webp": stamp_version2,
  "unfinished_recording.webp": unfinished_recording,
  "unreleased_2009_mix.webp": unreleased_2009_mix,
  "unreleased_original.webp": unreleased_original,
};

class Spacer extends Component {
  render() {
    return <span className="spacer"></span>;
  }
}

/* this is here to work around apparent bug in ReactCSSTransitionGroup
   every ONCE IN A WHILE it appears the transition group fails set the
   initial classes of the animation (which sets the initial positions) resulting
   in an animation glitch. hard to reproduce consistently and appears most frequently
   in firefox, occasionally in chrome, and have not yet seen it in safari.
   this animation is just a hacky workaround that sets the classes with timeouts
   to ensure the ordering.
*/
let currentInfoCardAnimation;
class InfoCardAnimation extends Component {
  constructor(...args) {
    super(...args);
    this.state = { animating: false, active: false };
  }
  trigger(done) {
    if (currentInfoCardAnimation.match(/none$/)) {
      setTimeout(done, 10);
      return;
    }

    let gap = 30;
    this.setState({ animating: true });
    setTimeout(() => {
      this.setState({ active: true });
    }, gap);
    setTimeout(() => {
      this.setState({ active: false, animating: false }, done);
    }, 1000);
  }
  componentWillEnter(done) {
    this.mode = "-enter";
    this.trigger(done);
  }
  componentWillLeave(done) {
    this.mode = "-leave";
    this.trigger(done);
  }
  getClassNames() {
    let { animating, active } = this.state;
    let transitionClass = currentInfoCardAnimation;

    if (animating) {
      return cn("info-card-animator-wrapper", {
        [transitionClass]: animating,
        [transitionClass + this.mode]: animating,
        [transitionClass + this.mode + "-active"]: active,
      });
    } else {
      return cn("info-card-animator-wrapper");
    }
  }
  render() {
    let cx = this.getClassNames();

    let entering = this.state.animating && this.mode === "-enter";
    let child = React.cloneElement(this.props.children, { entering });

    return (
      <div className={cx}>
        <VerticalCenter
          className={this.props.wrapperClass}
          key={this.props.itemKey}
        >
          {child}
        </VerticalCenter>
      </div>
    );
  }
}

function fontifyCopyrights(str) {
  if (!str) return str;

  var c = String.fromCharCode(169); //copyright symbol

  var ss = str.split(c);
  if (ss.length === 1) return str;

  var idx = 0,
    segments = [<span key={idx++}>{ss.shift()}</span>];
  while (ss.length) {
    segments.push(
      <span key={idx++} style={{ fontFamily: "DINPro" }}>
        {c}
      </span>
    );
    segments.push(<span key={idx++}>{ss.shift()}</span>);
  }

  return <span>{segments}</span>;
}

// TODO: move this to lib?
function normalizeImageSource(src, def = "") {
  return normalizeUrl(src || def);
}

// TODO: move this to lib?
function normalizeDuration(dur) {
  try {
    const segments = dur.split(":");
    const m = _.get(segments, "[0]", 0);
    const s = _.get(segments, "[1]", 0);
    return `PT${m}M${("0" + s).slice(-2)}S`;
  } catch (error) {
    return "PT0M00S";
  }
}

class InfoCard extends Component {
  constructor(props, ctx) {
    super(props, ctx);

    this.state = {
      showPopup: false,
      showPlaylistPopup: false,
      copy: "COPY",
      showMenuPlaylist: false,
      showEmbedModal: false,
    };

    this.showPopup = this.showPopup.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    this.showPlaylistPopup = this.showPlaylistPopup.bind(this);
    this.hidePlaylistPopup = this.hidePlaylistPopup.bind(this);
    this.toggleMenuPopup = this.toggleMenuPopup.bind(this);
    this.toggleEmbedModal = this.toggleEmbedModal.bind(this);
    this.setCopy = this.setCopy.bind(this);
    this.setCopy = this.setCopy.bind(this);
    this.parseItem = this.parseItem.bind(this);
  }
  componentDidMount() {
    const {
      track: {
        id: trackId,
        album,
        artist,
        title,
        lyrics,
        producer,
        releaseDate: { year } = {},
      } = {},
    } = this.props;
    const script = [];

    // TODO: move this to lib?
    console.log("%cthis.props.track", "color:deeppink;", this.props.track);

    const siteDomain = "https://neilyoungarchives.com";
    const siteName = "Neil Young Archives";
    const entryTitle = title || "(no title)";
    const entryImage = normalizeImageSource(
      _.get(album, "image"),
      `${siteDomain}/assets/img/neil-young-archives.jpg`
    );
    const entryUrl = `${siteDomain}/info-card?track=${trackId}`;
    const artistName = _.get(_.head(artist), "name");
    const label = this.props.track["label"];
    const session = this.props.track["session"];
    const musicians = this.props.track["musicians"];
    const albumId = _.get(album, "albumId");
    const albumTitle = _.get(album, "title");
    const albumTracks = _.get(album, "tracks", []);

    const breadcrumbList = {
      "@context": "http://schema.org/",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": siteDomain,
            name: siteName,
            image: `${siteDomain}/assets/img/neil-young-archives.jpg`,
          },
        },
      ],
    };

    if (albumId) {
      breadcrumbList.itemListElement.push({
        "@type": "ListItem",
        position: breadcrumbList.itemListElement.length + 1,
        item: {
          "@id": `${siteDomain}/album?id=${albumId}`,
          // originalRelease | Neil Young Archives
          name: _.compact([albumTitle, siteName]).join(" | "),
          image: entryImage,
        },
      });
    }

    if (trackId) {
      breadcrumbList.itemListElement.push({
        "@type": "ListItem",
        position: breadcrumbList.itemListElement.length + 1,
        item: {
          "@id": entryUrl,
          name: `${entryTitle} | ${siteName}`,
          image: entryImage,
        },
      });
    }

    /**
     * BreadcrumbList
     *
     * @items [home, album, track:self]
     */
    script.push(breadcrumbList);

    console.log("%cbreadcrumbList", "color:deeppink;", breadcrumbList);

    const musicComposition = {
      "@context": "http://schema.org",
      "@type": "MusicComposition",
      "@id": entryUrl,
      name: entryTitle,
      composer: [
        {
          "@type": "Person",
          name: "Neil Young",
          "@id": siteDomain,
        },
      ],
      inLanguage: "EN",
    };

    if (year) {
      musicComposition.datePublished = year;
    }

    if (producer) {
      musicComposition.producer = {
        "@type": "Person",
        name: producer,
        "@id": entryUrl,
      };
    }

    if (lyrics) {
      musicComposition.lyrics = {
        "@type": "CreativeWork",
        text: lyrics,
      };
    }

    /**
     * MusicComposition
     *
     * @props [name, composer, producer, publishedAt, lyrics]
     */
    script.push(musicComposition);

    console.log("%cmusicComposition", "color:deeppink;", musicComposition);

    const webPage = {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: _.compact([entryTitle, artistName]).join(" | "),
      // TODO: Create contentful entry
      // 'description': 'same content as Description meta tag',
      breadcrumb: _.compact(
        _.map(breadcrumbList.itemListElement, (list) =>
          list.item.name.replace(` | ${siteName}`, "")
        )
      ).join(" > "),
      // TODO: Create contentful entry
      // 'keywords': ['test', 'example'],
      // NOTE: significantLink are not expose to public
      // 'significantLink': [
      //     'https://neilyoungarchives.com/info-card/photos?track=t1964_0402_02',
      //     'https://neilyoungarchives.com/info-card/documents?track=t1964_0402_01',
      //     'https://neilyoungarchives.com/info-card/press?track=t1964_0402_01',
      //     'https://neilyoungarchives.com/info-card/memorabilia?track=t1964_0402_01',
      //     'https://neilyoungarchives.com/info-card?lyrics=1&track=t1964_0402_01'
      // ],
      mainEntity: _.pick(musicComposition, [
        "@type",
        "@id",
        "name",
        "composer",
        "inLanguage",
        "producer",
        "datePublished",
      ]),
    };

    if (albumId) {
      const relatedLink = albumTracks
        .filter((track) => track.id !== trackId)
        .map((track) => `${siteDomain}/info-card?track=${track.id}`);

      if (!_.isEmpty(relatedLink)) {
        relatedLink.shift(`${siteDomain}/album?id=${albumId}`);
        webPage.relatedLink = relatedLink;
      }
    }

    console.log("%cwebPage", "color:deeppink;", webPage);

    /**
     * WebPage
     *
     * @props [name, desc, breadcrumb, keywords (to add), significantLink, relatedLink, mainEntity (MusicComposition)]
     */
    script.push(webPage);

    if (albumId) {
      const musicAlbum = {
        "@context": "http://schema.org/",
        "@type": "MusicAlbum",
        name: albumTitle,
        image: entryImage,
        url: entryUrl,
        // NOTE: Create contentful entry?
        // 'genre': '',
        numtracks: albumTracks.length,
        track: albumTracks.map(({ id, title, length }, inx) => ({
          "@type": "MusicRecording",
          position: inx + 1,
          name: title,
          url: `${siteDomain}/info-card?track=${id}`,
          duration: normalizeDuration(length),
        })),
      };

      if (artistName) {
        musicAlbum.byArtist = {
          "@type": "MusicGroup",
          name: artistName,
        };
      }

      /**
       * MusicAlbum
       *
       * @props [name, byArtist, img, url, genre (to add), numtracks, track]
       */
      script.push(musicAlbum);

      console.log("%cmusicAlbum", "color:deeppink;", musicAlbum);
    }

    /**
     * MusicGroup
     *
     * @props [name*, alternateName*, logo*, image*, url (home?), ...]
     */
    // script.push(
    //     {
    //         "@context": "http://schema.org",
    //         "@type": "MusicGroup",
    //         "@id": "https://neilyoungarchives.com/album?id=A_064&photo=AA_A_064_01&tab=songs",
    //         "name": "The Squires", // artist
    //         "alternateName": "The Squires - Westgate High School", // artist -
    //         "logo": {
    //             "@type": "ImageObject",
    //             "url": "https://neilyoungarchives.com/images/23a8c1bcfe4dcc4caf09519d4e730c58.webp"
    //         },
    //         "image": {
    //             "@type": "ImageObject",
    //             "url": "https://neilyoungarchives.com/album?id=A_064&photo=AA_A_064_01&tab=songs&x=0.29236326289437775&y=0.1938189038335227&zoom=1"
    //         },
    //         "url": "https://neilyoungarchives.com",
    //         "genre": [
    //             "Classic Rock"
    //         ],
    //         "sameAs": [
    //             "https://www.facebook.com/username",
    //             "https://twitter.com/username",
    //             "https://instagram.com/username",
    //             "https://www.youtube.com/user/username",
    //             "https://soundcloud.com/username",
    //             "https://plus.google.com/username"
    //         ],
    //         // musicians
    //         "member": [
    //             {
    //                 "@type": "OrganizationRole",
    //                 "member": {
    //                     "@type": "Person",
    //                     "name": "Neil Young",
    //                     "sameAs": "https://yourwebsite.com/bio/"
    //                 },
    //                 "roleName": [
    //                     "guitar",
    //                     "vocals",
    //                     "harmonica"
    //                 ]
    //             },
    //             {
    //                 "@type": "OrganizationRole",
    //                 "member": {
    //                     "@type": "Person",
    //                     "name": "Allan Bates",
    //                     "sameAs": "https://yourwebsite.com/bio/"
    //                 },
    //                 "roleName": [
    //                     "guitar"
    //                 ]
    //             },
    //             {
    //                 "@type": "OrganizationRole",
    //                 "member": {
    //                     "@type": "Person",
    //                     "name": "Ken Koblun",
    //                     "sameAs": "https://yourwebsite.com/bio/"
    //                 },
    //                 "roleName": [
    //                     "guitar"
    //                 ]
    //             },
    //             {
    //                 "@type": "OrganizationRole",
    //                 "member": {
    //                     "@type": "Person",
    //                     "name": "Ken Smyth",
    //                     "sameAs": "https://yourwebsite.com/bio/"
    //                 },
    //                 "roleName": [
    //                     "drums"
    //                 ]
    //             },
    //             {
    //                 "@type": "OrganizationRole",
    //                 "member": {
    //                     "@type": "Person",
    //                     "name": "Barry Taylor",
    //                     "sameAs": "https://yourwebsite.com/bio/"
    //                 },
    //                 "roleName": [
    //                     "engineer"
    //                 ]
    //             }
    //         ]

    //     }
    // )

    const meta = [
      { property: "og:url", content: entryUrl },
      { property: "og:title", content: `${entryTitle} | ${siteName}` },
      { property: "og:image", content: entryImage },
    ];

    if (lyrics) {
      meta.push({
        property: "og:description",
        name: "description",
        content: lyrics,
      });
    }
    // metas for artist , label, musicians
    if (artistName) {
      meta.push({ name: "performer", content: artistName });
    }
    if (label) {
      meta.push({ name: "label", content: label });
    }
    if (musicians) {
      meta.push({ name: "musicians", content: musicians });
    }
    if (session) {
      meta.push({ name: "session", content: session });
    }

    updateMetadata({ title, meta, script });

    const item = this.parseItem(this.props.track);
    this.setState({ item, trackParsed: true });
  }
  UNSAFE_componentWillReceiveProps(newprops) {
    const { track } = this.props;
    let { track: newTrack } = newprops;
    const trackId = track && track.id;
    if (trackId !== undefined && trackId !== (newTrack && newTrack.id)) {
      const item = this.parseItem(newTrack);
      this.setState({ item, trackParsed: true, newSearch: true });
    }
  }
  buyLinks() {
    let { track } = this.props;
    let buyLinks = [];

    if (track.album && track.album.purchaseOptions) {
      if (track.album.purchaseOptions.pono) {
        const token = getOrastreamToken();
        const tokenString = token ? `?user_jwt=${token}` : "";

        const url = replaceStreamingUrl(
          track.album.purchaseOptions.pono,
          process.env.ORASTREAMLINK
        );
        buyLinks.push({
          title: "high-res from\nNYA Download Store",
          link: url + tokenString,
          type: "xstream",
        });
      }

      if (track.album.purchaseOptions.reprise) {
        buyLinks.push({
          title: "from neil young's\ngreedy hand store",
          link: track.album.purchaseOptions.reprise,
          type: "handStore",
        });
      }
    }

    return buyLinks.length > 0 ? buyLinks : null;
  }
  showPlaylistPopup() {
    this.setState({
      showPlaylistPopup: true,
      showMenuPlaylist: false,
      showEmbedModal: false,
    });
  }
  hidePlaylistPopup() {
    this.setState({ showPlaylistPopup: false });
  }
  toggleMenuPopup(showMenuPlaylist) {
    this.setState({ showMenuPlaylist });
  }
  toggleEmbedModal(showEmbedModal) {
    this.setState({ showEmbedModal, copy: "COPY", showPlaylistPopup: false });
  }
  setCopy() {
    this.setState({ copy: "COPIED" });
  }
  showPopup() {
    this.setState({ showPopup: true });
  }
  hidePopup() {
    this.setState({ showPopup: false });
  }
  parseItem(item) {
    const track = _.cloneDeep(item);
    const savedQuery = hasSearchedQuery() || false;

    if (savedQuery) {
      const queryTerms = (savedQuery || "").split(" ");
      const types = [
        "session",
        "performer",
        "producer",
        "musicians",
        "label",
        "engineer",
        "title",
        "lyrics",
      ];
      const replaceTemplate = "####";
      const replaceTemplateRegEx = new RegExp(`${replaceTemplate}`, "ig");

      for (let property in track) {
        if (typeof track[property] === "string" && types.includes(property)) {
          for (let i in queryTerms) {
            let word = queryTerms[i];

            word = word.replace(/[\’\“\”]/g, "'").replace(/[^\s\w\d]/g, "\\$&");

            let regEx = new RegExp(`${word}`, "ig");

            //capitilize words
            word = word.charAt(0).toUpperCase() + word.slice(1);
            let highlight = `<p id="${replaceTemplate}">${word}<p/>`;

            if (word) {
              track[property] = track[property].replace(regEx, highlight);
            }
          }
          track[property] = track[property].replace(
            replaceTemplateRegEx,
            "highlight"
          ); // using a template to replace what should be the highlight class
          track[
            property
          ] = `<span id="infocard-text">${track[property]}</span>`;
        }
      }
    }
    return track;
  }
  render() {
    let { entering } = this.props;
    let {
      item: track,
      showPopup,
      showPlaylistPopup,
      showEmbedModal,
      showMenuPlaylist,
      copy,
      trackParsed = false,
      newSearch,
    } = this.state;

    if (!trackParsed) return <SplashScreen loadState={-1} />;

    let {
      color,
      videos,
      orastream,
      unreleasedStamp,
      disclaimer,
      stampVersion,
      primaryTrack,
      linkedTrack,
      moreInfo,
      musiciansInProgress,
      id,
      releaseDateText,
      releaseDate,
      lyrics,
      audioFile,
      postItIcon,
      title,
      publisher,
      session,
      performer,
      producer,
      musicians,
      label,
      freeToDownload,
      outTake,
      demo,
      engineer,
    } = track;

    let { year, month, day } = releaseDate;
    let cx = cn({ card: true, [color]: true });
    let { history } = this.props;

    const locked = infoCardIsLockedByID(id);

    let date = releaseDateText || `${month}/${day}/${year}`;

    let hasVideos = videos && videos.length;
    let hasAudio = !!orastream;
    let hasLyrics = !!lyrics;

    let buyLinks = this.buyLinks();
    let hasBuyLinks = buyLinks && buyLinks.length > 0;

    let hasPostIt = !!(linkedTrack || moreInfo);
    let hasLinkBack = !hasPostIt && !!primaryTrack;
    let outTakeTrack = false;
    let demoTrack = false;
    let orastreamLink = process.env.ORASTREAMLINK;

    const token = getOrastreamToken();
    const stampImage = stampVersion
      ? stampVersion
      : unreleasedStamp
      ? stampImages[unreleasedStamp]
      : false;
    const freeDownloadLinks = [
      {
        title: "get free high-res track\nfrom NYA Download Store",
        link: orastreamLink + `?user_jwtt=${token}`,
        type: "xstream",
      },
    ];
    const freeDownloadButton = {
      title: "get free high-res track\nfrom NYA Download Store",
      link: orastreamLink + `?user_jwt=${token}`,
      type: "freeDownload",
    };

    let hasAsterix = date.indexOf("*") > -1;
    if (outTake) {
      outTakeTrack = true;
      demoTrack = false;
    }
    if (demo) {
      outTakeTrack = false;
      demoTrack = true;
    }
    let tabimage = cn({ "card-sticker": true, outTakeTrack, demoTrack });
    if (hasAsterix) date = date.replace(/\*/g, "");

    publisher = fontifyCopyrights(publisher);

    track.showAreYouStillListeningModal = true;

    return (
      <div className={cx}>
        <FolderTabs
          {...this.props}
          track={track}
          selected="title"
          disabled={locked}
        />
        <div className="folder-content">
          <div className="folder-image"></div>
          <div className={tabimage}>
            {stampImage && (
              <img className="stamp" src={stampImage} loading="lazy" />
            )}
            <div className="section song">
              <ScrollBars>
                <div dangerouslySetInnerHTML={{ __html: title || "" }} />
                <div className="publisher">{publisher}</div>
              </ScrollBars>
            </div>
            <div className="section performer">
              <ScrollBars>
                <div dangerouslySetInnerHTML={{ __html: performer || "" }} />
              </ScrollBars>
            </div>
            <div className="section date">
              <Spacer />
              {date}
              {hasAsterix && <div className="asterix">*</div>}
            </div>
            <div className="section session">
              <ScrollBars>
                <div dangerouslySetInnerHTML={{ __html: session || "" }} />
              </ScrollBars>
            </div>
            <div className="section producer-slash-label">
              <div className="section producer">
                <ScrollBars>
                  <p dangerouslySetInnerHTML={{ __html: producer || "" }} />
                </ScrollBars>
              </div>
              <div className="section label">
                <Spacer />
                <div dangerouslySetInnerHTML={{ __html: label || "" }} />
              </div>
            </div>
            <div className="section half">
              <div className="section musicians">
                <ScrollBars>
                  <div dangerouslySetInnerHTML={{ __html: musicians || "" }} />
                </ScrollBars>
                {musiciansInProgress && <div className="in-progress-stamp" />}
              </div>
              <div className=" section engineer">
                <ScrollBars>
                  <div dangerouslySetInnerHTML={{ __html: engineer }} />
                </ScrollBars>
              </div>
            </div>
            {disclaimer && <div className="disclaimer">{disclaimer}</div>}
          </div>
          <div className="buttons">
            {this.props.displaBackArrow && (
              <div
                className="performance-log-btn"
                onClick={() => history.back()}
              />
            )}
            {hasAudio && (
              <MusicPlayer
                makeStateInSync
                playInMiddle
                showAreYouStillListeningModal
                item={track}
                disabled={locked}
                newSearch={newSearch}
              />
            )}
            {hasAudio && (
              <div
                className="plusButton"
                onClick={() => this.toggleMenuPopup(true)}
              >
                {showPlaylistPopup && (
                  <div className="playlist-popup-wrapper">
                    <PlaylistPopup
                      hidePopup={this.hidePlaylistPopup}
                      style={{ top: "-320px", left: "-30px" }}
                      tracks={[track.id]}
                    />
                  </div>
                )}

                {showEmbedModal && (
                  <EmbedModal
                    copy={copy}
                    setCopy={this.setCopy}
                    track={track.id}
                    toggleEmbedModal={this.toggleEmbedModal}
                  />
                )}
                {showMenuPlaylist && (
                  <MenuPopup
                    hidePopup={this.toggleMenuPopup}
                    style={{ top: "-25px", left: "-30px" }}
                    showPlaylistPopup={this.showPlaylistPopup}
                    track={track.id}
                    toggleEmbedModal={this.toggleEmbedModal}
                    toggleMenuPopup={this.toggleMenuPopup}
                    type="SONG"
                  />
                )}
              </div>
            )}
            <div
              className="rsdSearchButton"
              onClick={() => {
                this.props.history.push("/find-store");
              }}
            ></div>
            {freeToDownload && (
              <div className="freeDownload" onClick={this.showPopup}>
                {showPopup && (
                  <div className="buy-popup-wrapper">
                    <BuyPopup
                      freeTrack
                      buyLinks={freeDownloadLinks}
                      hidePopup={this.hidePopup}
                      buttonLink={freeDownloadButton}
                    />
                  </div>
                )}
              </div>
            )}
            {!freeToDownload && hasBuyLinks && !track.outTake && (
              <div className="buy" onClick={this.showPopup}>
                {showPopup && (
                  <div className="buy-popup-wrapper">
                    <BuyPopup buyLinks={buyLinks} hidePopup={this.hidePopup} />
                  </div>
                )}
              </div>
            )}
            {!!hasVideos && (
              <div
                className={cn("video", { disabled: locked })}
                onClick={locked ? window.subs : this.props.onVideoClick}
              />
            )}
            {hasLyrics && (
              <div className="lyrics" onClick={this.props.onLyricsClick} />
            )}
          </div>
          <InfoCardAlbums
            track={track}
            entering={entering}
            newSearch={newSearch}
            onCoverClick={this.props.onCoverClick}
          />
          {hasLinkBack && (
            <div
              className="postit-return"
              onClick={this.props.onPostItReturnClick}
            />
          )}
          {hasPostIt && (
            <div className="postit-link" onClick={this.props.onPostItClick} />
          )}
          {audioFile && (
            <div
              className={cn("postit-audio", {
                "postit-audio--default": !postItIcon,
              })}
              onClick={this.props.onPostItAudioClick}
            >
              {postItIcon && <img src={postItIcon} loading="lazy" />}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function getIndex(id) {
  let items = getListedTracksForInfoCards();
  return _.findIndex(items, (item) => {
    return item.id === id;
  });
}
function getPct(id) {
  let items = getListedTracksForInfoCards();
  let idx = getIndex(id);
  return (idx / (items.length - 1)) * 100;
}
function getId(pct) {
  let items = getListedTracksForInfoCards();
  return items[Math.floor((pct / 100) * (items.length - 1))].id;
}

let assetsLoaded = true;

class InfoCardContainer extends Component {
  constructor(props, b) {
    super(props, b);

    const searchParams = new URLSearchParams(props.location.search);
    console.log("DOES LOGS PROPS EXIST?");
    console.log(props);
    let id = this.getTrackIdFromQuery(searchParams);

    setCurrentTrack(getTrackById(id));

    let position = getPct(id);
    let index = getIndex(id);

    let showLyrics = searchParams.get("lyrics") === "1";
    let showVideos = searchParams.get("videos") === "1";
    let showInfo = searchParams.get("info") === "1";
    let showPostIt = searchParams.get("postit") === "1";
    let videoThumbnails = [];

    this.state = {
      position,
      id,
      index,
      showLyrics,
      showInfo,
      showPostIt,
      showVideos,
      assetsLoaded,
      videoThumbnails,
    };

    this.onScroll = this.onScroll.bind(this);
    this.onLeft = this.onLeft.bind(this);
    this.onRight = this.onRight.bind(this);
    this.viewOnTimeline = this.viewOnTimeline.bind(this);
    this.viewTrack = this.viewTrack.bind(this);
    this.showAlbum = this.showAlbum.bind(this);
    this.showLyrics = this.showLyrics.bind(this);
    this.hideLyrics = this.hideLyrics.bind(this);
    this.showVideos = this.showVideos.bind(this);
    this.hideVideos = this.hideVideos.bind(this);
    this.showInfo = this.showInfo.bind(this);
    this.hideInfo = this.hideInfo.bind(this);
    this.hidePostIt = this.hidePostIt.bind(this);
    this.backToCabinet = this.backToCabinet.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.searchByDate = this.searchByDate.bind(this);
    this.postItClick = this.postItClick.bind(this);
    this.postItAudioClick = this.postItAudioClick.bind(this);
    this.postItReturnClick = this.postItReturnClick.bind(this);

    this.onPlaylistManagerEvent = _.debounce(
      this.onPlaylistManagerEvent.bind(this),
      1200
    );
  }
  getTrackIdFromQuery(searchParams) {
    if (searchParams.has("track") || searchParams.has("currTrack")) {
      return searchParams.get("track") || searchParams.get("currTrack");
    } else if (searchParams.has("year") && searchParams.has("month")) {
      let year = searchParams.get("year");
      let month = searchParams.get("month");
      let day = searchParams.get("day") || 1;

      month = monthNumber(month);
      year = parseInt(year, 10);
      day = parseInt(day, 10);

      let date = { year, month, day };
      let track = this.findNearestTrack(date);

      return track.id;
    }

    return getListedTracksForInfoCards()[0].id;
  }

  componentDidMount() {
    const { id } = this.state;
    this.getThumbnailsUrls(id);
    PlaylistManager.addListener(this.onPlaylistManagerEvent);
  }
  componentWillUnmount() {
    PlaylistManager.removeListener(this.onPlaylistManagerEvent);
  }
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const searchParams = new URLSearchParams(nextProps.location.search);
    let track = searchParams.get("track");
    let info = searchParams.get("info");
    if (!info && this.state.showInfo) this.setState({ showInfo: false });
    if (track && track !== this.state.id && track !== nextState.id) {
      this.viewTrack(track, 0);
    }
  }
  findNearestTrack(date) {
    let target = toTimestamp(date);
    let items = getListedTracksForInfoCards();
    let best, bestDiff;

    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      if (!item.releaseDate) continue;

      let time = toTimestamp(item.releaseDate);
      let diff = Math.abs(target - time);

      if (!best || diff < bestDiff) {
        best = item;
        bestDiff = diff;
      } else if (diff > bestDiff) {
        break;
      }
    }

    return best;
  }
  searchByDate(date) {
    let best = this.findNearestTrack(date);
    let curr = getTrackById(this.state.id);
    let dir = compareReleaseDate(best.releaseDate, curr.releaseDate) || -1;
    this.viewTrack(best.id, dir);
  }
  onLeft() {
    if (this.state.animating) return;
    this.move(-1);
  }
  onRight() {
    if (this.state.animating) return;
    this.move(+1);
  }
  move(dir) {
    let items = getListedTracksForInfoCards();
    let max = items.length - 1;
    let index = Math.max(0, Math.min(max, dir + this.state.index));
    let id = items[index].id;
    this.viewTrack(id, dir);
  }
  onPlaylistManagerEvent({ state, track }) {
    if (this.props.location.pathname !== "/info-card") return;
    if (track.id !== this.state.id) return;
    if (state !== "WILLPLAY") return;
    this.viewTrack(track.id, PlaylistManager.isPrevOrNext());
  }
  viewTrack(id, dir, position = getPct(id)) {
    let index = getIndex(id);
    let item = getTrackById(id);

    let changed = id !== this.state.id;

    let pushState = dir !== 0; //hack to work around post it notes linked tracks

    const keepLyrics = this.state.showLyrics && item.lyrics;
    const keepInfo = this.state.showInfo && item.moreInfo;
    const keepPostIt = this.state.showPostIt && item.audioFile;
    const keepVideos =
      this.state.showVideos && item.videos && item.videos.length > 0;

    this.setState(
      {
        position,
        id,
        index,
        lastDirection: dir,
        dragging: false,
        animating: true,
        showInfo: keepInfo,
        showPostIt: keepPostIt,
        showLyrics: keepLyrics,
        showVideos: keepVideos,
      },
      () => {
        if (changed)
          this.updateUrl(id, pushState, keepLyrics, keepInfo, keepVideos);
      }
    );

    this.getThumbnailsUrls(id);

    setTimeout(() => {
      this.setState({ animating: false });
    }, 1100);
  }

  postItClick(e) {
    e.preventDefault();
    e.stopPropagation();

    let track = getTrackById(this.state.id);
    let { moreInfo, linkedTrack } = track;

    if (moreInfo) {
      this.showInfo();
    } else {
      this.props.history.push(`/info-card?track=${linkedTrack.id}`);
    }
  }

  backToCabinet() {
    this.props.history.push("/drawer");
  }

  viewOnTimeline() {
    console.log("View on timeline!");
    this.props.history.push(
      "/timeline-months?" + getCurrentDateAsQueryString(true)
    );
  }

  showAlbum(albumId) {
    let album = getAlbumById(albumId);
    setCurrentTrack(album);
    this.props.history.push(`/album?id=${albumId}`);
  }

  postItReturnClick(e) {
    e.preventDefault();
    e.stopPropagation();

    this.props.history.goBack();
  }

  postItAudioClick(e) {
    e.preventDefault();
    e.stopPropagation();

    this.showPostIt();
  }
  postItReturnClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.history.goBack();
  }
  onDragStart() {
    this._startPosition = this.state.position;
    this.setState({ dragging: true });
  }
  onScroll(position) {
    this._endPosition = position;

    let id = getId(position);
    let track = getTrackById(id);

    this.setState({ position, sliderDate: track.releaseDate });
  }
  onDragEnd() {
    let direction = this._endPosition < this._startPosition ? -1 : 1;
    let position = this._endPosition;

    let id = getId(position);

    this.viewTrack(id, direction, position);
  }
  updateUrl(id, pushState = false, keepLyrics, keepInfo, keepVideos) {
    this.updateQuery(
      {
        track: id,
        year: null,
        month: null,
        day: null,
        lyrics: keepLyrics,
        info: keepInfo,
        videos: keepVideos,
      },
      pushState
    );
    setCurrentTrack(getTrackById(id));
  }

  updateQuery(params, pushState = false) {
    const { location, history } = this.props;
    const pathname = location.pathname;

    const searchParams = new URLSearchParams(location.search);

    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (typeof value === "undefined" || value === null || value === false) {
        searchParams.delete(key);
        searchParams.delete("search");
      } else {
        searchParams.set(key, value);
      }
    });

    const search = searchParams.toString();
    const newUrl = `${pathname}${search ? `?${search}` : ""}`;

    if (pushState) {
      history.push(newUrl);
    } else {
      history.replace(newUrl);
    }
  }

  backToCabinet() {
    let { history } = this.props;
    history.push("/drawer");
  }
  viewOnTimeline() {
    const { history } = this.props;
    history.push("/timeline-months?" + getCurrentDateAsQueryString(true));
  }
  showAlbum(albumId) {
    const { history } = this.props;
    let album = getAlbumById(albumId);
    setCurrentTrack(album);
    const prevLocation = this.props.location;
    history.push({
      pathname: "/album",
      search: `?id=${albumId}`,
      state: {
        modal: true,
        prevLocation: prevLocation,
      },
    });
  }
  showInfo() {
    this.setState({
      showLyrics: false,
      showVideos: false,
      showInfo: true,
      showPostIt: false,
    });
    this.updateQuery({ lyrics: null, videos: null, info: "1", postit: null });
  }
  hideInfo() {
    this.setState({ showInfo: false });
    this.updateQuery({ info: null });
  }
  showPostIt() {
    this.setState({
      showLyrics: false,
      showVideos: false,
      showInfo: false,
      showPostIt: true,
    });
    this.updateQuery({ lyrics: null, videos: null, info: null, postit: "1" });
  }
  hidePostIt() {
    this.setState({ showPostIt: false });
    this.updateQuery({ postit: null });
  }
  showLyrics() {
    this.setState({
      showLyrics: true,
      showVideos: false,
      showInfo: false,
      showPostIt: false,
    });
    this.updateQuery({ lyrics: "1", videos: null, info: null, postit: null });
  }
  hideLyrics() {
    this.setState({ showLyrics: false });
    this.updateQuery({ lyrics: null });
  }
  showVideos() {
    this.setState({
      showLyrics: false,
      showVideos: true,
      showInfo: false,
      showPostIt: false,
    });
    this.updateQuery({ lyrics: null, videos: "1", info: null, postit: null });
  }
  hideVideos() {
    this.setState({ showVideos: false });
    this.updateQuery({ videos: null });
  }
  getThumbnailsUrls(trackId) {
    // This method fetchs from vimeo api the urls for thumbnails, it is loaded here so there is no awaiting when its time to show the videos modal
    let track = getTrackById(trackId);
    let videos = track.videos.filter((v) => v); // Check if values are defined, could be null if videos were deleted from contentful

    if (videos.length > 0) {
      let thumbnailsPromises = videos.map((v) => getThumbnail(v.vimeoId));
      Promise.all(thumbnailsPromises).then((results) => {
        this.setState({
          videoThumbnails: results,
        });
      });
    }
  }
  renderContent() {
    let {
      id,
      position,
      index,
      showLyrics,
      sliderDate,
      dragging,
      animating,
      showVideos,
      lastDirection,
      showInfo,
      showPostIt,
    } = this.state;
    const { location } = this.props;
    let displaBackArrow = false;
    if (_.has(location, "state") && location.state == "performance-log")
      displaBackArrow = true;

    let items = getListedTracksForInfoCards();
    let item = getTrackById(id);

    let listed = index > -1;
    //next and prev btns are not display for tracks that arent related to an album
    let moreLeft = item && !item.album ? false : listed && index > 0;
    let moreRight =
      item && !item.album ? false : listed && index < items.length - 1;

    let transition =
      lastDirection < 0 ? "left" : lastDirection > 0 ? "right" : "none";

    currentInfoCardAnimation = `info-card-animate-${transition}`;

    let wrapperClass = (animating ? transition : "") + " wrapper";
    return (
      <div id="info-card" className="info-card-section">
        <div className="info-card-content">
          <ReactTransitionGroup>
            <InfoCardAnimation
              key={item.id}
              wrapperClass={wrapperClass}
              itemKey={item.id}
            >
              <InfoCard
                {...this.props}
                track={item}
                onCoverClick={this.showAlbum}
                onVideoClick={this.showVideos}
                onLyricsClick={this.showLyrics}
                onPostItClick={this.postItClick}
                displaBackArrow={displaBackArrow}
                onPostItAudioClick={this.postItAudioClick}
                onPostItReturnClick={this.postItReturnClick}
              />
            </InfoCardAnimation>
          </ReactTransitionGroup>
        </div>
        <LeftButton onClick={this.onLeft} show={moreLeft} />
        <RightButton onClick={this.onRight} show={moreRight} />
        <div className="bottom-panel">
          <div className="bottom-panel-center" />
          <div className="timeline-button" onClick={this.viewOnTimeline} />
          <div className="slider-container">
            <InfoCardSlider
              key="the-slider"
              pct={position}
              onScroll={this.onScroll}
              dragStart={this.onDragStart}
              dragEnd={this.onDragEnd}
              dragging={dragging}
              disabled={animating}
              date={sliderDate}
            />
          </div>
          <div className="date-container">
            <DateDisplay date={item.releaseDate} onSearch={this.searchByDate} />
          </div>
        </div>
        {showPostIt ? (
          <PostItAudio track={item} onClose={this.hidePostIt} />
        ) : null}
        {showInfo ? (
          <LyricsModal
            track={item}
            markdown={true}
            content="info"
            onClose={this.hideInfo}
          />
        ) : null}
        {showLyrics ? (
          <LyricsModal track={item} onClose={this.hideLyrics} />
        ) : null}
        {showVideos ? (
          <VideosModal
            track={item}
            onClose={this.hideVideos}
            thumbnails={this.state.videoThumbnails}
          />
        ) : null}
      </div>
    );
  }
  renderLoading() {
    return <SplashScreen loadState={-1} />;
  }
  render() {
    if (this.state.assetsLoaded) {
      return this.renderContent();
    } else {
      return this.renderLoading();
    }
  }
}

const mapStateToProps = function (state) {
  if (state.status !== "drawerLoaded") {
    return {
      loaded: false,
    };
  }

  return { loaded: true };
};

const InfoCardContainerWrapper = (props) => {
  if (!props.loaded)
    return (
      <div id="info-card" className="info-card-section">
        <SplashScreen loadState={100} />
      </div>
    );
  return <InfoCardContainer {...props} />;
};

export default withRouter(connect(mapStateToProps)(InfoCardContainerWrapper));