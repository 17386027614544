import React, { Component } from "react";
import cn from "classnames";
import Modal from "react-modal";
import { withRouter } from "react-router-dom";
import RouteTransitioner from "./components/route-transitioner";
import TopBar from "./components/global-top-bar";
import WarningMessage from "./components/warning-message";
import PlaylistManager from "./components/new-player/playlist-manager";
import { scale } from "./scale";
import History from "./history";
import { playSoundSprite } from "./sound";
import { handleDynamicDrawerTransition } from "./drawer-animation";
import _ from "lodash";
import Welcome from "./welcome";
import {
  hasSeenNewsletterPreference,
  getUserInfo,
  getShowModal,
  hasSeenLeavingMessage,
  hasFreePass,
  shouldHideTourMailingList,
} from "./services/api";
import { pageView } from "./services/tracking";
import translateLegacyUrls from "./services/translate-legacy-urls";
import regulateRenderedLinks from "./services/regulate-rendered-links";
import DrawerFront from "./drawer-front";
import AudioTag from "./components/new-player/mp3-player/mp3-wrapper";
import { pathInResponsiveViews } from "./route-utils";

// Mantener constantes y configuración global
//is embed ? false
const pageLocation = window.location.href;
const isEmbed = pageLocation.includes("embed");
let allowScaling = isEmbed ? false : true;

window.addEventListener("resize", resize, false);
function resize() {
  if (!allowScaling || pathInResponsiveViews()) return;

  let width = window.innerWidth;
  let height = window.innerHeight;

  let s = scale();
  let element = window.app;

  console.log("main-container", {
    isEmbed,
    element,
    scale: s,
  });

  if (isEmbed) {
    element.style.width = `100%`;
    element.style.height = `100%`;
    element.style.backgroundColor = "transparent !important";
  }

  if (element) {
    if (s !== 1) {
      let iscale = 1 / s;
      element.style.width = `${iscale * width}px`;
      element.style.height = `${iscale * height}px`;
      element.style.transformOrigin = "left top";
      element.style.transform = `scale(${s})`;
      element.className = "body-scaled";
    } else {
      element.style.width = `${width}px`;
      element.style.height = `${height}px`;
      element.style.transform = `none`;
      element.className = "body-unscaled";
    }
  }
}
resize();

window.unscaleViewport = function () {
  let width = window.innerWidth;
  let height = window.innerHeight;

  let element = document.body;

  element.style.width = `${width}px`;
  element.style.height = `${height}px`;
  element.style.transform = `none`;
  element.className = "body-unscaled";

  allowScaling = false;
};
window.rescaleViewport = function () {
  allowScaling = true;
  resize();
};

const isChrome = navigator.userAgent.search(/Chrome/) > -1;

/* static CSS transition works best for chrome,
   need to calculate drawer height for other platforms. */
const drawerTransition = isChrome ? "drawer-" : "x-drawer-";
if (!isChrome) {
  handleDynamicDrawerTransition();
}

const routeTransitions = [
  ["/test/a", "/test/b", "slide-down"],
  ["/test/b", "/test/a", "slide-up"],

  ["/info-card", "/test/a", "slide-up"],
  ["/test/a", "/info-card", "slide-down"],

  ["/timeline", "/test/a", "slide-up"],
  ["/test/a", "/timeline", "slide-down"],

  ["/info-card", "/timeline", "slide-up"],
  ["/timeline", "/info-card", "slide-down"],

  ["/info-card", "/timeline-months", "slide-up"],
  ["/timeline-months", "/info-card", "slide-down"],

  /* [/^\/drawer/, '/info-card', 'xfade'],
   * ['/info-card', /^\/drawer/, 'xfade'],*/

  [/^\/drawer/, "/test/a", "slide-up"],
  ["/test/a", /^\/drawer/, "slide-down"],

  /* [/^\/info-card/, /^\/info-card/, 'xfade'],*/

  [/^\/timeline/, /^\/timeline/, "xfade"],

  [
    "/",
    /^\/drawer/,
    drawerTransition + "slide-down",
    () => playSoundSprite("drawer-open"),
  ],
  [
    /^\/drawer/,
    "/",
    drawerTransition + "slide-up",
    () => playSoundSprite("drawer-open"),
  ],
];

/* pathnames that hide the top of the filing cabinet */
/* paths are at / */
/* when value is a string, it is tested as a query parameter */
const hiddenPathTable = {
  //search: true,
  news: true,
  "news-article": true,
  "top-40-tracks": true,
  "top-10-albums": true,
  faq: true,
  credits: true,
  "audio-setup": true,
  menu: true,
  newsletter: true,
  terms: true,
  "privacy-policy": true,
  video: true,
  contact: true,
  account: true,
  live: true,
  timeline: "photo",
  "timeline-months": "photo",
  album: "photo",
  film: "photo",
  "info-card/memorabilia": "photo",
  "info-card/documents": "photo",
  "info-card/press": "photo",
  "info-card/photos": "photo",
};

const regulateLinks = regulateRenderedLinks([
  ".excerpt",
  ".tab-description",
  ".faq-page",
  ".message",
]);

class MainContainer extends Component {
  constructor(props) {
    super(props);

    // Determine if its necessary to show newsletter preference modal
    let showNewsletterPref = false;
    let modalType;
    let warningMessageExtraData = {};

    const userInfo = getUserInfo();
    const showModal = getShowModal();

    let passError = JSON.parse(hasFreePass("pass-error"));
    let freePass = JSON.parse(hasFreePass("free-pass"));

    // get token and local storage
    if (userInfo && userInfo.user_metadata.firstLogin) {
      showNewsletterPref = !hasSeenNewsletterPreference();
      modalType = "newsletter";
    }
    if (showModal.showModal) {
      showNewsletterPref = showModal.showModal;
      modalType = showModal.modalType;
    }

    if (passError || freePass) {
      let tokenType = passError ? "pass-error" : "free-pass";
      showNewsletterPref = hasFreePass(tokenType);
      modalType = showNewsletterPref == "false" ? null : tokenType;
    }

    // ------------
    // Tour mailing list
    const adminShowTourMailingListModal =
      process.env.SHOW_TOUR_MAILING_LIST === "true";
    const hideTourMailingList = shouldHideTourMailingList();
    const isInTourMailingList =
      !!userInfo.user_metadata.is_in_tour_mailing_list;
    const shouldDisplayTourMailingListModal =
      adminShowTourMailingListModal &&
      !(hideTourMailingList || isInTourMailingList);
    const isWelcomeScreen =
      this.props.location && this.props.location.pathname === "/welcome";
    if (shouldDisplayTourMailingListModal && isWelcomeScreen) {
      showNewsletterPref = true;
      modalType = "tour-mailing-list";
      if (userInfo.email) {
        warningMessageExtraData["email"] = userInfo.email;
      }
    }
    // ------------
    this.state = {
      showWelcome: false /*, warning:'audio'*/,
      // showWelcome: !hasSeenWelcomeMessage(), /*, warning:'audio'*/
      warning: showNewsletterPref ? modalType : null,
      warningMessageExtraData,
      notification: null,
      playedWithoutActionTracks: {
        count: 0,
        lastPlayedId: "",
      },
    };

    this.hideWelcome = this.hideWelcome.bind(this);
    this.hideWarning = this.hideWarning.bind(this);
    this.showWarning = this.showWarning.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.hideNotification = this.hideNotification.bind(this);
    this.keepListeningListener = this.keepListeningListener.bind(this);
    this.keepListeningListenerMouseMove =
      this.keepListeningListenerMouseMove.bind(this);
    this.translateURLs = this.translateURLs.bind(this);
    this.onPopState = this.onPopState.bind(this);

    // Agregar bindings para nuevos métodos
    this.handlePreviousState = this.handlePreviousState.bind(this);
    this.getQueryParam = this.getQueryParam.bind(this);

    window.displayWarningMessage = this.showWarning;
    window.displayNotification = this.showNotification;
    window.subs = () => {
      this.showWarning("subscribe");
    };
    window.login = () => {
      this.showWarning("login");
    };
    window.siteInstruction = () => {
      this.showWarning("free-pass");
    };
    window.subsTicketMonthly = () => {
      this.showWarning("subscribeTicketMonthly");
    };
    window.ticketsUnavailable = () => {
      this.showWarning("ticketsUnavailable");
    };
    window.appleSubWarning = () => {
      this.showWarning("appleSub");
    };
    window.unlimitedAcced = () => {
      this.showWarning("unlimitedOnly");
    };
    window.disableSubscriptionsPurchase = () => {
      this.showWarning("subscriptions-purchase-disabled");
    };
    window.unlimitedAccedV2 = () => {
      this.showWarning("unlimitedOnlyV2");
    };
    window.showTourMailingList = () => {
      this.showWarning("tour-mailing-list");
    };
    window.showNewsletterModal = () => {
      this.showWarning("newsletter");
    };
    PlaylistManager.addListener(this.keepListeningListener);

    if (this.props.location) {
      this.maybeNotePageView(null, props.location);
    }
  }

  keepListeningListenerMouseMove(e) {
    this.setState((prevState) => ({
      playedWithoutActionTracks: { count: 0, lastPlayedId: "" },
    }));
    window.removeEventListener(
      "mousemove",
      this.keepListeningListenerMouseMove
    );
  }

  keepListeningListener({ state, track }) {
    const { playedWithoutActionTracks } = this.state;
    if (
      PlaylistManager.isShowAreYouStillListeningModal() &&
      state === "WILLPLAY" &&
      track.id !== playedWithoutActionTracks.lastPlayedId
    ) {
      this.setState((prevState) => {
        const playedTracks = prevState.playedWithoutActionTracks.count + 1;

        if (playedTracks > 10) {
          PlaylistManager.pause();
          window.displayWarningMessage("playback-interruption");
          return { playedWithoutActionTracks: { count: 0, lastPlayedId: "" } };
        }
        return {
          playedWithoutActionTracks: {
            count: playedTracks,
            lastPlayedId: track.id,
          },
        };
      });

      window.addEventListener("mousemove", this.keepListeningListenerMouseMove);
    }
  }

  // Actualizado para React Router v4
  shouldComponentUpdate(nextProps, nextState) {
    // Si el estado cambió, debe actualizarse
    if (this.state !== nextState) {
      return true;
    }

    // Verificar que location existe antes de acceder a sus propiedades
    if (!this.props.location || !nextProps.location) {
      return true;
    }

    // Si la ruta o los parámetros de búsqueda cambiaron, debe actualizarse
    if (
      nextProps.location.pathname !== this.props.location.pathname ||
      nextProps.location.search !== this.props.location.search
    ) {
      return true;
    }

    return false;
  }

  hideWelcome() {
    this.setState({ showWelcome: false });
  }

  pathnameForAnalytics(loc) {
    // Verificar que loc existe antes de acceder a sus propiedades
    if (!loc) return "";

    // drawer, timeline, and timeline-months have position
    // information that shouldn't track as separate pageviews
    if (
      loc.pathname === "/drawer" ||
      loc.pathname === "/timeline" ||
      loc.pathname === "/timeline-months"
    ) {
      return loc.pathname;
    } else if (loc.search) {
      return loc.pathname + loc.search;
    } else {
      return loc.pathname;
    }
  }

  maybeNotePageView(prevlocation, newlocation) {
    if (!newlocation) return;

    if (
      prevlocation &&
      this.pathnameForAnalytics(prevlocation) ===
        this.pathnameForAnalytics(newlocation)
    ) {
      return;
    }

    pageView(this.pathnameForAnalytics(newlocation));
  }

  pathnameIsModal(path) {
    let table = {
      "/search": true,
      "/album": true,
      "/performance-log/tour": true,
      "/menu": true,
      "/playlist": true,
      "/film": true,
      "/video": true,
      "/live": true,
      "/request-modal": true,
    };
    return table[path];
  }

  // Método actualizado para React Router v4
  componentDidUpdate(prevProps) {
    // Restaurar scroll si es necesario (se mantiene de la versión original)
    if (!!this.prevChildren) {
      setTimeout(() => {
        this.scrollToPrevChildrenScroll(this.prevChildrenScroll);
      }, 0);
    }

    // Verificar si la ubicación cambió
    if (this.props.location !== prevProps.location) {
      // Actualizar analíticas
      this.maybeNotePageView(prevProps.location, this.props.location);

      // Actualizar traducción de URLs
      this.translateURLs();

      // Manejar estado previo para modales y rutas anidadas
      this.handlePreviousState(prevProps);

      // Actualizar History si está disponible
      if (History && typeof History.updatePathname === "function") {
        History.updatePathname(this.props.location.pathname);
      }
    }
  }

  // Método para actualizar estado previo (reemplaza UNSAFE_componentWillReceiveProps)
  handlePreviousState(prevProps) {
    if (!this.props.location || !prevProps.location) return;

    const currP = prevProps.location.pathname;
    const newP = this.props.location.pathname;

    // Verificar tipos especiales de rutas
    const ispvvModal = newP.includes("movietone") && /\d/g.test(newP);
    const ppvExtraModal = currP.includes("movietone") && newP.includes("film");

    if (currP === newP && !ispvvModal) return;

    // Manejar el cambio entre modales y vistas normales
    if (this.pathnameIsModal(newP) || ispvvModal) {
      if (this.pathnameIsModal(currP) || ppvExtraModal) {
        // No almacenar el contenido previo si estamos mostrando
        // un modal sobre otro modal
        if (
          currP.includes("tour") ||
          currP.includes("request-modal") ||
          ppvExtraModal
        ) {
          this.middleChildren = prevProps.children;
          this.tourLocation = currP;
        }
      } else {
        // Guardar el contenido y scroll anteriores
        this.prevChildren = prevProps.children;
        this.prevChildrenScroll = this.getScrolledHeight();
        this.prevLocation = prevProps.location.pathname;
        this.prevQuery = prevProps.location.search;
        this.tourLocation = null;
      }
    } else {
      // Resetear cuando volvemos a una vista no-modal
      this.prevChildren = null;
      this.prevChildrenScroll = null;
      this.prevLocation = null;
      this.prevQuery = null;
      this.tourLocation = null;
    }
  }

  componentDidMount() {
    // Verificar si es una vista modal
    let currP = this.props.location ? this.props.location.pathname : "";
    let ppv = currP.includes("movietone") && !currP.includes("news");

    if (
      (this.pathnameIsModal(currP) && !this.prevChildren) ||
      (ppv && !this.prevChildren)
    ) {
      this.prevChildren = <DrawerFront />;
      this.prevChildrenScroll = this.getScrolledHeight();
      this.prevLocation = this.props.location
        ? this.props.location.pathname
        : "";
      this.prevQuery = this.props.location ? this.props.location.search : "";
    }

    // Traducir URLs y configurar event listeners
    this.translateURLs();
    window.addEventListener("hashchange", this.translateURLs, false);
    window.addEventListener("popstate", this.onPopState, false);
  }

  componentWillUnmount() {
    // Limpieza de event listeners
    window.removeEventListener("hashchange", this.translateURLs, false);
    window.removeEventListener("popstate", this.onPopState, false);
    PlaylistManager.removeListener(this.keepListeningListener);
  }

  // Método auxiliar para obtener parámetros de búsqueda en React Router v4
  getQueryParam(search, paramName) {
    if (!search) return null;
    const searchParams = new URLSearchParams(search);
    return searchParams.get(paramName);
  }

  // Actualizado para React Router v4
  translateURLs() {
    const { history, location } = this.props;
    if (!location) return;

    // Usar history de React Router v4 para navegación
    if (typeof translateLegacyUrls === "function") {
      translateLegacyUrls({ history, location });
    }

    // Usar history.push para navegación programática
    if (typeof regulateLinks === "function") {
      regulateLinks((path) => history.push(path));
    }
  }

  onPopState() {
    if (document.location.hash !== "") {
      history.back();
    }
  }

  scrollToPrevChildrenScroll(prevChildScroll) {
    let wrapper = document.querySelector(".page-wrapper");
    if (wrapper) {
      wrapper.scrollTop = prevChildScroll;
    }
  }

  getScrolledHeight() {
    let wrapper = document.querySelector(".page-wrapper");
    if (wrapper) {
      return wrapper.scrollTop;
    }
    return 0;
  }

  showTopBar(currentPathname, currentQuery) {
    return true; // Set to always true as it wasn't appearing on some required instances
    // Si no hay rutas para esconder, mostrar siempre
    if (!hiddenPathTable) return true;

    // Si estamos en una modal, usar la ruta anterior
    let isModal = this.pathnameIsModal(currentPathname);
    let pathname =
      (isModal ? this.prevLocation : currentPathname) || currentPathname;
    let query = (isModal ? this.prevQuery : currentQuery) || currentQuery;

    // Remover la barra inicial para matchear con tabla
    pathname = pathname.substr(1);

    // Verificar si debe estar oculto
    if (hiddenPathTable[pathname] === true) return false;

    // Si el valor en la tabla es un string, verificar si está en la query
    if (typeof hiddenPathTable[pathname] === "string") {
      const paramName = hiddenPathTable[pathname];
      const paramValue = this.getQueryParam(query, paramName);
      return !paramValue;
    }

    return true;
  }

  transitions() {
    return routeTransitions;
  }

  durations() {
    return { crossfade: 500, "slide-up": 1000, "slide-down": 1000, xfade: 500 };
  }

  overlayChildren(children) {
    let style = { width: "100%", height: "100%" };
    let renderChildren = children ? children : this.props.children;
    return (
      <div className="overlay">
        <div className="content-wrapper" style={style}>
          {renderChildren}
        </div>
      </div>
    );
  }

  welcomeMessage() {
    if (!this.state.showWelcome) return null;

    let style = { width: "100%", height: "100%" };
    return (
      <div className="welcome-overlay">
        <div className="content-wrapper" style={style}>
          <Welcome onClose={this.hideWelcome} />
        </div>
      </div>
    );
  }

  showWarning(warning) {
    this.setState({ warning });
  }

  hideWarning() {
    this.setState({ warning: null });
  }

  warningMessage() {
    const { warning, warningMessageExtraData } = this.state;
    if (!warning) return null;
    let style = { width: "100%", height: "100%" };
    const isLeavingWarning = warning.type && warning.link;

    if (isLeavingWarning && hasSeenLeavingMessage(warning.type)) {
      window.open(warning.link);
      this.hideWarning();
      return;
    }

    return (
      <div
        className={cn("warning-overlay", {
          "warning-overlay--leaving": isLeavingWarning,
        })}
      >
        <div className="content-wrapper" style={style}>
          <WarningMessage
            isLeavingWarning={isLeavingWarning}
            which={warning.type || warning}
            link={warning.link}
            data={warningMessageExtraData}
            onClose={this.hideWarning}
            {...this.props}
          />
        </div>
      </div>
    );
  }

  showNotification(notification) {
    this.setState({ notification });

    window.setTimeout(this.hideNotification, 5000);
  }

  hideNotification() {
    this.setState({ notification: null });
  }

  notificationMessage() {
    const { notification } = this.state;
    if (!notification) return null;

    return (
      <div
        className={cn("notification", {
          [notification.type]: notification.type,
        })}
      />
    );
  }

  render() {
    let style = { width: "100%", height: "100%" };
    let pathname = "",
      search = "";

    if (this.props.location) {
      pathname = this.props.location.pathname;
      search = this.props.location.search;
    }

    let backgroundChildren = false;

    let prev = !!this.prevChildren;

    // previous tour location
    if (
      (this.tourLocation && pathname && pathname.includes("video")) ||
      (this.tourLocation && pathname && pathname.includes("film"))
    ) {
      backgroundChildren = this.middleChildren;
    }

    Modal.setAppElement("#app");

    return (
      <div id="main-wrapper">
        <div id="content">
          <AudioTag />
          <RouteTransitioner
            location={this.props.location}
            paths={this.transitions()}
            durations={this.durations()}
          >
            <div className="content-wrapper" style={style}>
              {prev ? this.prevChildren : this.props.children}
            </div>
          </RouteTransitioner>
        </div>
        {backgroundChildren ? this.overlayChildren(backgroundChildren) : null}
        {prev ? this.overlayChildren(false) : null}
        {this.welcomeMessage()}
        {this.warningMessage()}
        {this.notificationMessage()}
        {this.showTopBar(pathname, search) ? <TopBar path={pathname} /> : null}
      </div>
    );
  }
}

// Exportar con withRouter para tener acceso a location, history y match
export default withRouter(MainContainer);
